import React from 'react'
import { graphql, StaticQuery } from "gatsby"
import Img from 'gatsby-image'
import { injectIntl, FormattedMessage } from "gatsby-plugin-intl"


import { Layout } from '../components/common'

const Obras = ({data, intl}) => {

const imagens = data.allFile.edges




function toggleModal(imagem) {
  const body = document.querySelector('body')
  const modal = document.querySelector('.modal')
  const imgModal = document.querySelector('.imgModal')
  modal.classList.toggle('opacity-0')
  modal.classList.toggle('pointer-events-none')
  body.classList.toggle('modal-active')
  
  if(imagem!==null){
    imgModal.src=imagem.childImageSharp.fluid.src
  }

}

return(
    <>
        <Layout title="Obras de Arte - Lupercio Manoel e Souza" meta="Dono de um estilo peculiar e cheio de personalidade, confira as últimas obras do artista.">
            <div className="container">
                <h1>{intl.formatMessage({ id: "obras" })}</h1>
                <div className="w-full bg-4a6389 p-10">
                    <b><div dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "ArteCriticaTitulo" })}}></div></b><br/>
                    <p><div dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "ArteCritica" })}}></div></p>
                    <p className="text-right text-lg">- <div dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "ArteCriticaAutor" })}}></div></p>
                  </div>
                
                <div className="grid gap-2 xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2">
                 
                {imagens && imagens.map(imagem => (                        

                  <div className="overflow-hidden shadow-lg gap-2" key={imagem.node.id} >
                    <a className="cursor-pointer" onClick={() => { toggleModal(imagem.node) }}><Img className="w-full transition duration-500 ease-in-out transform hover:-translate-y-2 hover:scale-125" fluid={imagem.node.childImageSharp.fluid} alt={imagem.node.id} /></a>
                  </div>

                ))}
                </div>
                
            </div>
  
            
  <div className="modal opacity-0 pointer-events-none fixed w-full h-full top-0 left-0 flex items-center justify-center transition duration-500 ease-in-out" onClick={()=>{toggleModal(null)}}>
    <div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-75">

      
    </div>
    
    <div className="modal-container bg-white mx-auto rounded shadow-lg z-50 overflow-y-auto">
      
      <div className="modal-close absolute top-0 right-0 cursor-pointer flex flex-col items-center mt-4 mr-4 text-white text-sm z-50">
        <svg className="fill-current text-white" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 20 20">
          <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
        </svg>
        <span className="text-xl"><b>{intl.formatMessage({ id: "Voltar" })}</b></span>
      </div>

      
      <div className="p-4 text-left">        
        <img className="imgModal" />
      </div>

    </div>
  </div>


        </Layout>
    </>
)
}

const obrasQuery = graphql`
  query {
    allFile(filter: {absolutePath: {regex: "/images/obras-de-arte/"}}) {
      edges {
        node {
          id
          absolutePath
          childImageSharp {
            fluid(maxHeight: 512) {
                ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
  
`

const ObrasLayoutQuery = props => (
  <StaticQuery
    query={obrasQuery}
    render={data => (
      <Obras location={props.location} data={data} {...props} />
    )}
  />
)

export default injectIntl(ObrasLayoutQuery)
